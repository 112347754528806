import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MantParametrosComponent } from './views/mant-parametros/mant-parametros.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ParametroService } from './services/parametro.service';
import { MantPlanesComponent } from './views/mant-planes/mant-planes.component';
import { PlanesService } from './services/planes.service';
import { MantProveedorComponent } from './views/mant-proveedor/mant-proveedor.component';
import { ContribuyenteService } from './services/contribuyente.service';
import { LoginService } from './services/login.service';
import { MantUsuarioComponent } from './views/mant-usuario/mant-usuario.component';
import { UsuarioService } from './services/usuario.service';
import { MantPerfilComponent } from './views/mant-perfil/mant-perfil.component';
import { PerfilService } from './services/perfil.service';
import { MantOpcionComponent } from './views/mant-opcion/mant-opcion.component';
import { OpcionService } from './services/opcion.service';
import { MantRznsclusuarioComponent } from './views/mant-rznsclusuario/mant-rznsclusuario.component';
import { RznsclusuarioService } from './services/rznsclusuario.service';
import { EncdecService } from './services/encdec.service';
import { MantOpcionperfilComponent } from './views/mant-opcionperfil/mant-opcionperfil.component';
import { PerfilesService } from './services/perfiles.service';
import { OpcionmenuService } from './services/opcionmenu.service';
import { MantActualizacionAppComponent } from './views/mant-actualizacion-app/mant-actualizacion-app.component';
import { ActualizacionAppService } from './services/actualizacion-app.service';
import { MantUsuarioRazonSocialComponent } from './views/mant-usuario-razon-social/mant-usuario-razon-social.component';
import { UsuarioRazonSocialService } from './services/usuario-razon-social.service';
import { environment } from 'src/environments/environment';
import { JwtModule, JwtHelperService } from '@auth0/angular-jwt';
import { RecaptchaModule } from 'ng-recaptcha';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RznsclserieanexoService } from './services/rznsclserieanexo.service';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { MantNewContrasenaComponent } from './seguridad/mant-new-contrasena/mant-new-contrasena.component';
import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';
import { TreeDragDropService } from 'primeng/api';
import { LayoutsModule } from './layouts/layouts.module';
import { PrimengModule } from './primeng/primeng.module';
import { MantParametroProveedorComponent } from './views/mant-parametro-proveedor/mant-parametro-proveedor.component';
import { MantTipoContactoComponent } from './views/mant-tipo-contacto/mant-tipo-contacto.component';
import { MantTipobajaComponent } from './views/mant-tipobaja/mant-tipobaja.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MyProfileComponent } from './components/user/my-profile/my-profile.component';




export function tokenGetter() {

  let tk = JSON.parse(localStorage.getItem(environment.TOKEN_NAME));
  tk = JSON.parse(tk)
  let token = tk != null ? tk.access_token : '';
  return token;
}

@NgModule({
  // componentes
  declarations: [
    AppComponent,
    //MenuComponent,
    MantParametrosComponent,
    MantPlanesComponent,
    MantProveedorComponent,
    //LoginComponent,
    MantUsuarioComponent,
    MantPerfilComponent,
    MantOpcionComponent,
    MantRznsclusuarioComponent,
    MantOpcionperfilComponent,
    MantActualizacionAppComponent,
    MantUsuarioRazonSocialComponent,
    MantNewContrasenaComponent,
    MantParametroProveedorComponent,
    MantTipoContactoComponent,
    MantTipobajaComponent,
    MyProfileComponent
  ],
  // modulos o librerias
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MessagesModule,
    RecaptchaModule,
    PrimengModule,
    LayoutsModule,
    GoogleMapsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        //allowedDomains: ['localhost:8084'], //['165.227.89.201'], //['localhost:8080'],
        //disallowedRoutes: ['http://localhost:8082/oauth/token']
          //  blacklistedRoutes: ['http://localhost:8080/oauth/token'] //['http://localhost:8080/oauth/token']
        // produccion
        //allowedDomains: ['localhost:8084'],
        ///disallowedRoutes: ['http://localhost:8084/oauth/token']
        // beta
        allowedDomains: ['securitybeta.mifact.pe'], //['165.227.89.201'], //['localhost:8080'],
        disallowedRoutes: ['https://securitybeta.mifact.pe/apisecurityws/oauth/token', /https:\/\/apibeta.mifact.pe\/apialfaws\/api*/ ]//['http://165.227.89.201/oauth/token'] //['http://localhost:8080/oauth/token']

        // demo
        // allowedDomains: ['apisecuritydemo.mifact.pe'],//,['165.227.89.201'], //['localhost:8080'],
        // disallowedRoutes: ['https://apisecuritydemo.mifact.pe/apisecuritydemo/oauth/token',]//['http://165.227.89.201/oauth/token'] //['http://localhost:8080/oauth/token']

      }
    })
  ],
  // servicios
  providers: [

    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    ParametroService,
    MessageService,
    PlanesService,
    ContribuyenteService,
    LoginService,
    UsuarioService,
    PerfilService,
    OpcionService,
    RznsclusuarioService,
    EncdecService,
    PerfilesService,
    OpcionmenuService,
    TreeDragDropService,
    ActualizacionAppService,
    UsuarioRazonSocialService,
    RznsclserieanexoService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
